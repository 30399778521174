import React, {useState} from "react";
import {NauticalLocationType} from "../../interfaces/INauticalLocation";
import {Form} from "react-bootstrap";
import {fetchNauticalLocationByUUID} from "../../rest/fetchNauticalLocations";
import {useForm} from "react-hook-form";
import {ErrorMessage} from '@hookform/error-message';
import {tokenStorage} from "../auth/TokenStorage";


type SearchLocationFormProps = {
    callback: (n?: NauticalLocationType) => void
}

const SearchLocationForm: React.FC<SearchLocationFormProps> = ({callback}) => {
    const getTimeNowPlus = (milliseconds: number = 0) => new Date().getTime() + milliseconds

    const [requestTimer, resetRequestTimer] = useState(getTimeNowPlus())

    const { register,  handleSubmit, setError, formState: { errors } , clearErrors} = useForm()

    const searchResult = (accessToken, uuid) => {
        if(accessToken && getTimeNowPlus() > requestTimer) {
            resetRequestTimer(getTimeNowPlus(2000))
            return  fetchNauticalLocationByUUID(accessToken, uuid)
        }}

    const onSubmit = (e) => {
        const keyVals = Object.entries(e).map(([key, value]) => [key, value])
        const search = Object.fromEntries(keyVals)
        const token = tokenStorage.getAccessToken()
        searchResult(token, search['searchBy'])
            .then(callback)
            .catch(e => {
                setError('singleErrorInput', {type: "server", message: e.message})
            })
    }

    return (
        <Form className="d-flex" onSubmit={handleSubmit(onSubmit)} onChange={() => clearErrors()}>
            <ErrorMessage errors={errors as any} name="singleErrorInput" />
            <input {...register("searchBy", {required: true})} className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-success" type="submit">Search</button>
            <button className="btn btn-outline-success" type="button" onClick={() => callback(null)}>Reset</button>
        </Form>
    )
}

export default SearchLocationForm;
