import L, {LatLngExpression} from "leaflet";
import {NauticalLocationType} from "../interfaces/INauticalLocation";
import {match} from "ts-pattern";
import {Geometry} from "geojson";

export const COLOUR_DEFAULT = {color: 'rgba(39, 151, 245, 0.8)'}
export const COLOUR_PBP = {color: 'rgba(99, 39, 245, 0.8)'}
export const COLOUR_ANCHOR_AREA = {color: 'rgba(245, 86, 39, 0.6)'}
export const COLOUR_BERTH = {color: 'rgba(245,39,135,0.6)'}
export const COLOUR_PORTBASIN = {color: 'rgba(39, 151, 245, 0.6)'}
export const COLOUR_TERMINAL = {color: 'rgba(207,39,245,0.8)'}

export const featureColour = (featureType) => {
    switch (featureType) {
        case 'berth': return COLOUR_BERTH
        case 'pilotBoardingPlace': return COLOUR_PBP
        case 'anchorArea': return COLOUR_ANCHOR_AREA
        case 'portBasin': return COLOUR_PORTBASIN
        case 'terminal': return COLOUR_TERMINAL
        default: return COLOUR_DEFAULT
    }
}



export const NLRTM = 'NLRTM'
export const USHOU = 'USHOU'
export const ESALG = 'ESALG'
export const GBMSC = 'GBMSC'
export const USMIA = 'USMIA'
export const USNYC = 'USNYC'

export const MAP_CENTER_NLRTM: LatLngExpression = [51.90741068112112, 3.8356018066406254]
export const MAP_CENTER_USHOU: LatLngExpression = [29.433563, -94.812885]
export const MAP_CENTER_ESALG: LatLngExpression = [36.13672, -5.434271]

export const MAP_CENTER_GBMSC: LatLngExpression = [53.46733, -2.318764]
export const MAP_CENTER_USMIA: LatLngExpression = [25.7529657, -80.12167]
export const MAP_CENTER_USNYC: LatLngExpression = [40.5659236, -73.50386]

export const PortCenters = new Map<string, LatLngExpression>([
    [NLRTM, MAP_CENTER_NLRTM],
    [USHOU, MAP_CENTER_USHOU],
    [ESALG, MAP_CENTER_ESALG],
    [GBMSC, MAP_CENTER_GBMSC],
    [USMIA, MAP_CENTER_USMIA],
    [USNYC, MAP_CENTER_USNYC],
])

export const MAX_BOUNDS = L.latLngBounds([[90, -180], [-90, 180]])

export const NLRTM_BOUNDS = L.latLngBounds([[51.587309751245456, 2.9786682128906254], [52.22443459871999, 4.692535400390626]])

export const MAP_DEFAULTS = {
    center: MAP_CENTER_NLRTM,
    minZoom: 3,
    maxBounds: MAX_BOUNDS,
    scrollWheelZoom: true,
    zoom: 10
}

export const firstPointOfGeometry: (location: NauticalLocationType) => LatLngExpression = location => {
    const result =  match<Geometry, [number, number]>(location.geo)
        // @ts-ignore
        .with({type: 'Point'}, () => location.geo.coordinates)
        // @ts-ignore
        .with({type: 'LineString'}, () => location.geo.coordinates[0])
        // @ts-ignore
        .with({type: 'MultiPolygon'}, () => location.geo.coordinates[0][0][0])
        // @ts-ignore
        .otherwise(() => location.geo.coordinates[0][0])

    // convert coordinate to LatLngExpression
    return [result[1], result[0]]
}
