import NauticalLocationFormInput, {LocationFormInputProps} from "./NauticalLocationFormInput";
import "../LocationEditor.css"
import {Form} from "react-bootstrap";
import {formatInputValue} from "../LocationEditor";

const PortFormInput: React.FC<LocationFormInputProps> = ({location, register, onInputChange}) => {
    return (
        <Form.Group className="mb-3" controlId="PortFormInput">
            <NauticalLocationFormInput location={location} register={register} onInputChange={onInputChange}/>
            <Form.Label>Timezone:</Form.Label>
            <Form.Control value={formatInputValue(location['timeZone'])} {...register("timeZone", { required: true })} required onChange={ e => {
                onInputChange("timeZone", e.target.value)
            }} />

            <Form.Label>Center:</Form.Label>
            <Form.Control as="textarea" value={location['center'] ? formatInputValue(location['center']) : "{}"} {...register("center", { required: true })} required onChange={ e => {
                onInputChange("center", JSON.parse(e.target.value))
            }} />

        </Form.Group>
    );
}

export default PortFormInput;
