import {ErrorResponse, handleResponse} from "./handleResponse";
import {LatLngBounds} from "leaflet";
import {NauticalLocationType} from "../interfaces/INauticalLocation";
import {createHttpGetOptions} from "./request.util";

export const fetchNauticalLocations = (authToken: string, zoomLevel: number, bounds: LatLngBounds): Promise<NauticalLocationType[]> => {
    const url = '/locations'
    const endpoint = url + `?${boundsToQueryParams(bounds)}`;

    const options = createHttpGetOptions(authToken)
    return fetch(endpoint, options).then(handleResponse)
}

export const fetchNauticalLocationByUUID = (authToken: string, uuid: string): Promise<NauticalLocationType | ErrorResponse> => {
    const endpoint = `/locations/byId/${uuid}`
    const options = createHttpGetOptions(authToken)
    return fetch(endpoint, options)
        .then(handleResponse)
}

const boundsToQueryParams  = (bounds: LatLngBounds) => {
    const NE = bounds.getNorthEast()
    const SW = bounds.getSouthWest()
    const coords =
        `coordinates=(${NE.lng}, ${NE.lat})&` +
        `coordinates=(${SW.lng}, ${SW.lat})&`
    return coords

}
