import NauticalLocationFormInput, { LocationFormInputProps} from "./NauticalLocationFormInput";
import "../LocationEditor.css"
import {Form} from "react-bootstrap";
import {formatInputValue, safeJsonParse} from "../LocationEditor";

const PilotBoardingPlaceFormInput: React.FC<LocationFormInputProps> = ({location, register, onInputChange}) => {
    return (
        <Form.Group className="mb-3" controlId="PilotBoardingPlaceFormInput">
            <NauticalLocationFormInput location={location} register={register} onInputChange={onInputChange}/>
            <Form.Label>Radius:</Form.Label>
            <Form.Control value={formatInputValue(location['radius'])} {...register("radius", { required: true })} required onChange={ e => {
                onInputChange("radius", e.target.value)
            }} />

            <Form.Label>Center:</Form.Label>
            <Form.Control as="textarea" value={location['center'] ? formatInputValue(location['center']) : "{}"}
                          {...register("center", { required: true, validate: v => safeJsonParse(v) })} required
                          onChange={ e => {onInputChange("center", e.target.value)}}
            />
        </Form.Group>

    );
}

export default PilotBoardingPlaceFormInput;
