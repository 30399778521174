import React, {useEffect, useState, useCallback} from "react";
import { MapContainer, TileLayer} from "react-leaflet";
import NauticalLocationLayer from "../nautical-location/NauticalLocationLayer";
import {MAP_DEFAULTS, NLRTM, NLRTM_BOUNDS, PortCenters} from "../../constants/NauticalLocationConstants";
import {NavBar} from "../nav/NavBar";
import {
    berthMooringTypesSelector,
    nauticalLocationTypes,
    NauticalLocationTypeSelect
} from "../nautical-location/NauticalLocation.util";
import {IBerth, NauticalLocationType} from "../../interfaces/INauticalLocation";
import { tokenStorage } from '../auth/TokenStorage';
import {fetchNauticalLocations} from "../../rest/fetchNauticalLocations";
import {LatLngBounds} from "leaflet";
import {LocationEditor} from "../location-editor/LocationEditor";

const Explorer: React.FC = () => {
    const [visibleLocationType, setVisibleLocationType] = useState([nauticalLocationTypes[1]])
    const [visibleBerthMooringType, setVisibleBerthMooringType] = useState(berthMooringTypesSelector)
    const [searchResult, setSearchResult] = useState<NauticalLocationType>(null)

    const [center, setCenter] = useState(PortCenters.get(NLRTM))
    const [zoom, setZoom] = useState<number>(10)
    const [bounds, setBounds] = useState<LatLngBounds>(NLRTM_BOUNDS)

    // location that system passes to LocationEditor for editing
    const [location, setLocation] =  useState(null)
    // location that system submits/posts to the backend successfully.
    const [createdNewLocation, setCreatedNewLocation] = useState<NauticalLocationType>(null)

    // locations that we get from api(backend)
    const [locations, setLocations] = useState<NauticalLocationType[]>([])
    // locations that we want to render them in the NauticalLocationLayer.
    const [locationsToBeRendered, setLocationsToBeRendered] = useState<NauticalLocationType[]>([])


    const getTimeNowPlus = (milliseconds: number = 0) => new Date().getTime() + milliseconds
    const [requestTimer, resetRequestTimer] = useState(getTimeNowPlus())

    // eslint-disable-next-line
    const requestLocations = useCallback((accessToken) => {
        if (accessToken && getTimeNowPlus() > requestTimer) {
            resetRequestTimer(getTimeNowPlus(2000));
            return fetchNauticalLocations(accessToken, zoom, bounds)
                .catch(error => {
                    console.error("Error fetching nautical locations:", error);
                    throw error; // Propagate the error to be handled in the caller
                });
        } else {
            return Promise.resolve([]);
        }
    }, [zoom, bounds, requestTimer]);

    // eslint-disable-next-line
    useEffect(() => {
        const accessToken = tokenStorage.getAccessToken();
        requestLocations(accessToken)
            .then(setLocations)
            .catch(error => {
                console.error("Error in fetching locations:", error);
            });
    }, [zoom, bounds, requestLocations]);

    const isBerthVisible: (loc: NauticalLocationType) => Boolean = loc =>  {
        if (loc.type === 'berth') {
            const berth = loc as IBerth
            const mooringType = berth.mooringType || 'NoMooringType'
            return visibleBerthMooringType.map(l=>l.label).includes(mooringType)
        } else {
            return true
        }
    }

    useEffect(() => {
        if(locations) {
            const filtered = locations
                .filter(loc => visibleLocationType.map(l => l.label).includes(loc.type))
                .filter(loc => isBerthVisible(loc))
            setLocationsToBeRendered(filtered)
        }
        //     eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations, visibleLocationType, visibleBerthMooringType])



    const onVisibleLocationTypeChange = (types: NauticalLocationTypeSelect[]) => {
        setVisibleLocationType(types)
    }

    const onVisibleBerthTypeChange = (types: NauticalLocationTypeSelect[]) => {
        setVisibleBerthMooringType(types)
    }

    const onSearchResult = (n?: NauticalLocationType) => {
        if(n) {
            setSearchResult(n)
            setLocationsToBeRendered([n])
        } else {
            setSearchResult(null)
            setLocationsToBeRendered(locations)
        }
    }

    const onViewPort = (zoomLevel: number, bounds: LatLngBounds) => {
        setZoom(zoomLevel)
        setBounds(bounds)
    }

    const onSelectedPortCallback = (port: string) => {
        setCenter(PortCenters.get(port))
    }

    const onLocation = (location: NauticalLocationType) => {
        setLocation(location)
    }

    /**
     * They are the same locations but different UUID because NLMDB backend will ignore the uuid which is submitted by frontend.
     * System needs the frontend uuid to be able to remove this location in the newLocations category in the NauticalLocationLayer
     * @param locationReturnedFromBackend
     * @param locationSubmittedByFrontend
     */
    const onSubmit = (locationReturnedFromBackend: NauticalLocationType, locationSubmittedByFrontend) => {
        const filtered = locationsToBeRendered
            .filter(l => l.uuid !== locationReturnedFromBackend.uuid)
            .filter(l => l.uuid !== locationSubmittedByFrontend?.uuid)

        setLocationsToBeRendered(filtered.concat([locationReturnedFromBackend]))
        setCreatedNewLocation(locationSubmittedByFrontend)
    }

    return (
        <div>
            <NavBar
                onSelectedPort={onSelectedPortCallback}
                onSelectedLocationType={onVisibleLocationTypeChange}
                defaultVisibleLocationType={visibleLocationType}
                onSearchResultChanged={onSearchResult}
                onSelectedBerthType={onVisibleBerthTypeChange}
            />
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <MapContainer style={{height: "85vh"}} {...MAP_DEFAULTS} >
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                noWrap={true}
                            />
                            <NauticalLocationLayer location={location} searchResult={searchResult} mapCenter={center} onForm={onLocation} locations={locationsToBeRendered} onViewPortChange={onViewPort} createdNewLocation={createdNewLocation}/>
                        </MapContainer>
                    </div>
                    <LocationEditor onClose={() => {
                        setLocation(null)
                    }} location={location} onSubmit={onSubmit} key={location?.uuid} />
                </div>
            </div>
        </div>
    )
}

export default Explorer;
