import React from "react"
import {Dropdown} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {tokenStorage} from "../auth/TokenStorage";

const Menu: React.FC<{email: string, setIsAuthenticated: (auth: boolean) => void}> = ({email, setIsAuthenticated }) => {
    const history = useHistory();

    const handleLogout = () => {
        tokenStorage.deleteTokens();
        setIsAuthenticated(false);
        history.push('/');
    };

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Logged in as: {email}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
                    <Dropdown.Item href="/all-pending-changes">Sync changes</Dropdown.Item>
                    <Dropdown.Item href="/">Map</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default Menu;
