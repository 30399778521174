import React from "react";
import { useState } from 'react';
import { tokenStorage } from './TokenStorage';
import { signIn } from "./CognitoService";
import { useHistory } from 'react-router-dom';

const LoginButton: React.FC<{ setIsAuthenticated: (auth: boolean) => void }> = ({ setIsAuthenticated }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const history = useHistory();

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        if (emailError) setEmailError('');
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        if (passwordError) setPasswordError('');
    };

    const validateForm = () => {
        let valid = true;
        if (!email) {
            setEmailError('Email is required');
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Invalid email address');
            valid = false;
        }

        if (!password) {
            setPasswordError('Password is required');
            valid = false;
        }

        return valid;
    };

    const handleSignIn = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const result = await signIn(email, password);
                tokenStorage.saveTokensIfExists(result);
                if (tokenStorage.isAuthenticated()) {
                    setIsAuthenticated(true); // Update authentication state
                    history.push('/');
                } else {
                    alert("Login Failed: Invalid token");
                }
            } catch (error) {
                console.error('Error during sign in:', error);
                alert(`Sign in failed: ${error.message || error}`);
            }
        } else {
            console.log("Form validation failed");
        }
    };

    return (
        <form onSubmit={handleSignIn}>
            <div className="d-flex align-items-center w-100">
                <input
                    className={emailError ? "form-control border-danger me-2 flex-grow-1" : "form-control border-primary me-2 flex-grow-1"}
                    type="email" id="email" value={email} onChange={handleEmailChange} placeholder="Email"
                    required />
                {emailError && <div className="invalid text-danger me-2">{emailError}</div>}

                <input
                    className={passwordError ? "form-control border-danger me-2 flex-grow-1" : "form-control border-primary me-2 flex-grow-1"}
                    type="password" id="password" value={password} onChange={handlePasswordChange}
                    placeholder="Password" required />
                {passwordError && <div className="invalid text-danger me-2">{passwordError}</div>}

                <button type="submit" className="btn btn-primary w-50">Log in</button>
            </div>
        </form>
    );
};

export default LoginButton;

