import NauticalLocationFormInput, {
    LocationFormInputProps
} from "./NauticalLocationFormInput";
import {useEffect, useState} from "react";
import "../LocationEditor.css"
import {Form, Tab, Tabs} from "react-bootstrap";
import {formatInputArrayValue, formatInputValue} from "../LocationEditor";
import {berthMooringTypesSelector} from "../../nautical-location/NauticalLocation.util";

const BerthFormInput: React.FC<LocationFormInputProps> = ({location, register, onInputChange}) => {
    const [bollardStart, setBollardStart] = useState<number | null>(location['bollardStart'])
    const [bollardEnd, setBollardEnd] = useState<number | null>(location['bollardEnd'])
    const [bollards, setBollards] = useState<number[]>(location['bollards'])

    const createBerthBollardsFromStartAndEnd = (start, end) => {
        return Array.from({
            length: end - start + 1
        }, (_, k) => parseInt(start) + k)
    }

    useEffect(() => {
        setBollards(createBerthBollardsFromStartAndEnd(bollardStart, bollardEnd))
    }, [bollardStart, bollardEnd])

    useEffect(() => {
        onInputChange("bollards", bollards)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bollards])


    const onBollardsStartChange = (evt) => {
        setBollardStart(evt.target.value)
        onInputChange("bollardStart", evt.target.value)
    }

    const onBollardsEndChange = (evt) => {
        setBollardEnd(evt.target.value)
        onInputChange("bollardEnd", evt.target.value)
    }

    return (
        <Tabs defaultActiveKey="basic">
            <Tab eventKey="basic" title="Basic">
                <NauticalLocationFormInput location={location} register={register} onInputChange={onInputChange}/>
            </Tab>
            <Tab eventKey="other-info" title="Other Info">
                <Form.Group className="mb-3" controlId="BerthFormInput">
                    <Form.Label>ID:</Form.Label>
                    <Form.Control defaultValue={formatInputValue(location['id'])} {...register("id", { required: true })} required onChange={ e => {
                        onInputChange("id", e.target.value)
                    }} />

                    <Form.Label>Name Long:</Form.Label>
                    <Form.Control placeholder="Name long"  value={formatInputValue(location['nameLong'])} {...register("nameLong", { required: true })} required onChange={ e => {
                        onInputChange("nameLong", e.target.value)
                    }} />

                    <Form.Label>Name Short:</Form.Label>
                    <Form.Control placeholder="Name short" value={formatInputValue(location['nameShort'])} {...register("nameShort", { required: true })} required onChange={ e => {
                        onInputChange("nameShort", e.target.value)
                    }} />
                    <Form.Label>Mooring Type:</Form.Label>
                    <Form.Control as={"select"} defaultValue={location['mooringType'] === undefined || location['mooringType'] === null ? 'Steiger' : formatInputValue(location['mooringType'])}
                                 onChange={ e => { onInputChange('mooringType', e.target.value)} } {...register("mooringType", { required: false })}>
                        {
                            berthMooringTypesSelector.map(({value, label}, index) =>
                                <option key={index} value={value}>{label}</option>)
                        }
                    </Form.Control>

                    <Form.Label>Terminal UUID:</Form.Label>
                    <Form.Control value={formatInputValue(location['terminalUuid'])} {...register("terminalUuid")} onChange={ e => {
                        onInputChange("terminalUuid", e.target.value)
                    }} />

                    <Form.Label>Quay UUID:</Form.Label>
                    <Form.Control value={formatInputValue(location['quayUuid'])} {...register("quayUuid")} onChange={ e => {
                        onInputChange("quayUuid", e.target.value)
                    }} />

                    <Form.Label>Bollard Start:</Form.Label>
                    <Form.Control type="number" value={formatInputValue(location['bollardStart'])} {...register("bollardStart")} onChange={onBollardsStartChange} />

                    <Form.Label>Bollard End:</Form.Label>
                    <Form.Control type="number" value={formatInputValue(location['bollardEnd'])} {...register("bollardEnd")} onChange={onBollardsEndChange} />

                    <Form.Label>Bollards:</Form.Label>
                    <Form.Control value={formatInputArrayValue(bollards)} {...register("bollards")} readOnly/>
                </Form.Group>
            </Tab>
        </Tabs>
    );
}

export default BerthFormInput;
