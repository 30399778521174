import {NauticalLocationType} from "../../interfaces/INauticalLocation";

export enum LocationTypes {
    port,
    customNauticalLocation,
    portBasin,
    pilotBoardingPlace,
    anchorArea,
    terminal,
    berth,
    quay,
    lighteringArea
}

export const nauticalLocationSortFunction = (loc1: NauticalLocationType, loc2: NauticalLocationType) => {
    return LocationTypes[loc1.type] - LocationTypes[loc2.type]
}

export const nameOf = (locationEnum: number) => LocationTypes[locationEnum]

export interface NauticalLocationTypeSelect {
    value: number|string,
    label: string
}

export const nauticalLocationTypes: NauticalLocationTypeSelect[] = [
    {value: LocationTypes.berth, label: nameOf(LocationTypes.berth)},
    {value: LocationTypes.port, label: nameOf((LocationTypes.port))},
    {value: LocationTypes.portBasin, label: nameOf((LocationTypes.portBasin))},
    {value: LocationTypes.anchorArea, label: nameOf((LocationTypes.anchorArea))},
    {value: LocationTypes.lighteringArea, label: nameOf((LocationTypes.lighteringArea))},
    {value: LocationTypes.customNauticalLocation, label: nameOf((LocationTypes.customNauticalLocation))},
    {value: LocationTypes.pilotBoardingPlace, label: nameOf((LocationTypes.pilotBoardingPlace))},
    {value: LocationTypes.quay, label: nameOf((LocationTypes.quay))},
    {value: LocationTypes.terminal, label: nameOf((LocationTypes.terminal))}
]

export const berthMooringTypesSelector: NauticalLocationTypeSelect[] = [
    {value: 'Paal', label: 'Paal'},
    {value: 'Remmingwerk', label: 'Remmingwerk'},
    {value: 'Steigerdek', label: 'Steigerdek'},
    {value: 'Boei', label: 'Boei'},
    {value: 'Kade', label: 'Kade'},
    {value: 'Geen', label: 'Geen'},
    {value: 'Steiger', label: 'Steiger'},
    {value: 'Dobberpaal', label: 'Dobberpaal'},
    {value: 'Ponton', label: 'Ponton'},
    {value: 'NoMooringType', label: 'NoMooringType'},
]

export const terminalTypesSelector: NauticalLocationTypeSelect[] = [
    {value: 'BreakBulk', label: 'BreakBulk'},
    {value: 'Chemical', label: 'Chemical'},
    {value: 'Container', label: 'Container'},
    {value: 'Crude', label: 'Crude'},
    {value: 'DryBulk', label: 'DryBulk'},
    {value: 'LNG', label: 'LNG'},
    {value: 'LPG', label: 'LPG'},
    {value: 'Pax', label: 'Pax'},
    {value: 'Product', label: 'Product'},
    {value: 'Reefer', label: 'Reefer'},
    {value: 'RoRo', label: 'RoRo'}
]

