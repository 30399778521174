import React, {useState} from "react";
import {PortSelector} from "../header/PortSelector";
import NauticalLocationTypeSelector from "../nautical-location/NauticalLocationTypeSelector";
import {LocationTypes, nameOf, NauticalLocationTypeSelect} from "../nautical-location/NauticalLocation.util";
import SearchLocationForm from "../header/SearchLocationForm";
import {NauticalLocationType} from "../../interfaces/INauticalLocation";
import BerthTypeSelector from "../nautical-location/BerthTypeSelector";

type NavBarProps = {
    defaultVisibleLocationType: NauticalLocationTypeSelect[]
    onSelectedPort: (selectedPort: string) => void,
    onSelectedLocationType: (types: NauticalLocationTypeSelect[]) => void
    onSearchResultChanged: (result?: NauticalLocationType) => void
    onSelectedBerthType:(types: NauticalLocationTypeSelect[]) => void
}

export const NavBar: React.FC<NavBarProps> = ({onSelectedPort, onSelectedLocationType, defaultVisibleLocationType, onSearchResultChanged, onSelectedBerthType}) => {
    const [isBerthTypeSelectorVisible, setIsBerthTypeSelectorVisible] = useState(false)

    const onChange = (types: NauticalLocationTypeSelect[]) => {
        setIsBerthTypeSelectorVisible(types.map(l => l.label).includes(nameOf(LocationTypes.berth)))
        onSelectedLocationType(types)
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-2 mb-1">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <PortSelector callback={onSelectedPort} />
                        <NauticalLocationTypeSelector callBack={onChange} locationType={defaultVisibleLocationType} />
                        {isBerthTypeSelectorVisible? <BerthTypeSelector callback={onSelectedBerthType} /> : <div/>}
                    </ul>
                    <SearchLocationForm callback={onSearchResultChanged} />
                </div>
            </div>
        </nav>
    )
}
