import React, {useState, useEffect} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Header from "../header/Header";
import ProtectedRoute from "../auth/ProtectedRoute";
import Explorer from "../explorer/Explorer";
import LogoutButton from "../auth/LogoutButton";
import {tokenStorage} from "../auth/TokenStorage";

const App: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(tokenStorage.isAuthenticated());
    const [, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const checkAuth = () => {
            setIsAuthenticated(tokenStorage.isAuthenticated());
        };
        window.addEventListener('storage', checkAuth);
        return () => {
            window.removeEventListener('storage', checkAuth);
        };
    }, []);

    useEffect(() => {
        try {
            setEmail(tokenStorage.getEmail());
        } catch (error) {
            console.error("Error in useEffect:", error);
            setErrorMessage("An error occurred while fetching the user name.");
        }
    }, []);

    return (
        <BrowserRouter>
            <Header
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                email={tokenStorage.getEmail()}
                errorMessage={errorMessage} />
            <Switch>
                <ProtectedRoute
                    path="/"
                    exact
                    component={Explorer}
                    isAuthenticated={isAuthenticated}
                    setErrorMessage={setErrorMessage}
                />
                <Route path="/logout" exact render={() => <LogoutButton setIsAuthenticated={setIsAuthenticated} />} />
            </Switch>
        </BrowserRouter>
    );
};

export default App;
