export interface ErrorResponse {
    status: number,
    message: string
}

export const handleResponse: (response: Response) => Promise<any> = response =>
    handleAnyResponse(response)
        .then(resp => resp.json())

export const handleAnyResponse: (response: Response) => Promise<Response> = response => {
    if (response.status === 401) {
        throw new Error(`Unauthorized ${response.status}`)
    } else if (response.status === 403) {
        return Promise.reject({status: response.status, message: 'Forbidden'})
    } else if(response.status === 404) {
        return Promise.reject({status: response.status, message: 'Not found'})
    } else if(response.status === 400) {
        return Promise.reject({status: response.status, message: 'Bad request'})
    } else if (response.status === 500) {
        throw new Error(`Something went wrong! ${response.status} - ${response.statusText}`)
    } else if (response.status === 503) {
        throw new Error(`App is starting up. Stay put and try again after a couple of minutes!`)
    } else {
        return Promise.resolve(response)
    }
}
