import React, {useEffect, useState} from "react"
import {Alert} from "react-bootstrap";

export type AlertMessageType =
    'success' | 'danger'

export type AlertMessageProps = {
    alertType?: AlertMessageType,
    message?: string
}
const AlertMessage: React.FC<AlertMessageProps> = ({alertType, message}) => {
    const [show, setShow] = useState<boolean>(false)
    const [variant, setVariant] = useState<AlertMessageType>(alertType)

    const onCloseAlert = () => {
        setShow(false)
        setVariant(null)
    }

    useEffect(() => {
        setVariant(alertType)
        if(alertType) {
            setShow(true)
        }
    }, [alertType])

    if(show) {
        return (
            <Alert variant={variant} onClose={onCloseAlert} dismissible>
                {message}
            </Alert>
        )
    } else {
        return null
    }
}

export default AlertMessage;
