import {Geometry, LineString, Point} from 'geojson'
import { v4 as uuidv4 } from 'uuid';

export type NauticalLocationType = INauticalLocation | EmptyNauticalLocation | IBerth | ITerminal | IPortBasin | IQuay | IPilotBoardingPlace | IAnchorArea | ILighteringArea | ICustomNauticalLocation | IPort

export interface INauticalObject {
    uuid: string,
    geo: Geometry,
    urn: string,
    urns: string[]
}
export const nauticalObjectKeys: (keyof INauticalObject)[] = ["uuid", "geo", "urn", "urns"]

export interface INauticalLocation extends INauticalObject{
    name: string,
    port: string,
    type: string,
    aliases: string[]
}
export const nauticalLocationKeys: (keyof INauticalLocation)[] = [...nauticalObjectKeys, "name", "port", "type", "aliases"]

export interface EmptyNauticalLocation extends INauticalLocation {
    isNewLocation?: boolean
    [key: string]: any
}

export interface IBerth extends INauticalLocation {
    id:string,
    nameLong: string,
    nameShort: string,
    harbourName?: string,
    harbourNameShort?: string,
    terminalUuid?: string,
    quayUuid?: string,
    bollardStart?: number,
    bollardEnd?: number,
    bollards: number[],
    length: number,
    width: number,
    maxLOA?: number,
    draught: number,
    mooringType?: MooringType,
    neighbouringBerthUuids: string[],
    mooringSide?: LineString,
    purpose?: NauticalLocationPurpose
}
export const berthKeys: (keyof IBerth | keyof INauticalLocation | keyof INauticalObject)[] = [...nauticalLocationKeys,
    "id", "nameLong", "nameShort", "harbourName", "harbourNameShort", "terminalUuid", "quayUuid", "bollardStart", "bollardEnd", "bollards",
    "length", "width", "maxLOA", "draught", "mooringType", "neighbouringBerthUuids", "mooringSide", "purpose"]


export interface IPort extends INauticalLocation {
    center?: Point,
    timeZone?: string
}
export const portKeys: (keyof IPort)[] = [...nauticalLocationKeys, "center", "timeZone"]


export interface ITerminal extends INauticalLocation {
    berthUuids: string[],
    terminalTypes: TerminalType[],
    generalContactInfo?: IGeneralContactInfo
    laybyBerthInfo?: ILaybyBerthInfo
}

export const isTerminalLocation = (location: INauticalLocation): location is ITerminal =>
    'terminalTypes' in location

export const terminalKeys: (keyof ITerminal)[] = [...nauticalLocationKeys, "berthUuids", "generalContactInfo", "laybyBerthInfo"]


export interface IQuay extends INauticalLocation {
    berthUuids: string[],
    bollards: IBollard[]
}
export const quayKeys: (keyof IQuay)[] = [...nauticalLocationKeys, "berthUuids", "bollards"]


export interface IPilotBoardingPlace extends INauticalLocation {
    center?: Point,
    radius?: number
}
export const pbpKeys: (keyof IPilotBoardingPlace)[] = [...nauticalLocationKeys, "center", "radius"]

export interface IAnchorArea extends INauticalLocation {
    ports?: string[]
}
// NauticalLocation types which did not have any different attribute to INauticalLocation
type IPortBasin = INauticalLocation
type ICustomNauticalLocation = INauticalLocation
type ILighteringArea = INauticalLocation

export const anchorAreaKeys: (keyof IAnchorArea)[] = [...nauticalLocationKeys, "ports"]
export const portBasinKeys: (keyof IPortBasin)[] = nauticalLocationKeys
export const customNauticalLocationKeys: (keyof ICustomNauticalLocation)[] = nauticalLocationKeys
export const lighteringAreaKeys: (keyof ILighteringArea)[] = nauticalLocationKeys

// ----------------------------
//    Supporting interfaces
// ----------------------------

interface ILaybyBerthInfo {
    bookingPhoneNumber?: string,
    bookingEmail?: string,
    pricingInfoUrl?: string
}

interface IGeneralContactInfo {
    phoneNumber?: string,
    email?: string
}
export const generalContactInfoKeys: (keyof IGeneralContactInfo)[] = ["phoneNumber", "email"]


interface IBollard extends INauticalObject {
    number?: number,
    quayUUID?: string
}
export const bollardKeys: (keyof IBollard)[] = [...nauticalObjectKeys, "number", "quayUUID"]


export type MooringType = "Paal" | "Remmingwerk" | "Steigerdek" | "Boei" | "Kade" |"Geen" | "Steiger" | "Dobberpaal" | "Ponton"
type NauticalLocationPurpose = "ContainerBerth"

export type TerminalType = "BreakBulk" | "Chemical" | "Container" | "Crude" | "DryBulk" |"LNG" | "LPG" | "Pax" | "Product" | "Reefer" | "RoRo"

export const getLocationTypeKeys = (locType: string) => {
    switch (locType) {
        case 'port': return portKeys
        case 'berth': return berthKeys
        case 'terminal': return terminalKeys
        case 'pilotBoardingPlace': return pbpKeys
        case 'portBasin': return portBasinKeys
        case 'quay': return quayKeys
        case 'anchorArea': return anchorAreaKeys
        case 'customNauticalLocation': return customNauticalLocationKeys
        case 'lighteringArea': return lighteringAreaKeys
        default: {
            console.warn(`Unrecognised nautical location type: ${locType}`)
            return nauticalLocationKeys
        }
    }
}


export const createEmptyNauticalLocation = (locType: string) => {
    let emptyObj: EmptyNauticalLocation = {
        aliases: [],
        geo: undefined,
        name: "",
        port: "",
        type: locType,
        urn: "",
        urns: [],
        uuid: uuidv4(),
        isNewLocation: true
    }

    for(let key of getLocationTypeKeys(locType)) {
        if(!emptyObj[`${key}`])
            emptyObj[`${key}`] = null
    }
    return({...emptyObj, type: locType})
}

export const coordinatesToGeoJsonPoint = (coords: {lat: number, lng: number}) => {
    return {
        "type": "Point",
        "coordinates": [coords.lng, coords.lat]
    }
}
