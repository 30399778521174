import React, {useEffect, useState} from 'react'
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import {useMap, useMapEvents} from "react-leaflet";
import NauticalLocation from "./NauticalLocation";
import {nauticalLocationSortFunction} from "./NauticalLocation.util";
import {
    coordinatesToGeoJsonPoint,
    createEmptyNauticalLocation,
    NauticalLocationType
} from "../../interfaces/INauticalLocation";
import {LatLngBounds, LatLngExpression} from "leaflet";
import {firstPointOfGeometry} from "../../constants/NauticalLocationConstants";

const circleToPolygon = require("circle-to-polygon");

type NauticalLocationLayerProps = {
    location: NauticalLocationType | null
    searchResult?: NauticalLocationType
    createdNewLocation?: NauticalLocationType
    mapCenter?: LatLngExpression
    onForm: (location: NauticalLocationType) => void,
    locations: NauticalLocationType[],
    onViewPortChange: (zoomLevel: number, bounds: LatLngBounds) => void
}
const NauticalLocationLayer: React.FC<NauticalLocationLayerProps> = (
    {searchResult, createdNewLocation, mapCenter,  onForm, locations, onViewPortChange, location}) =>{

    const [newLocations, setNewLocations] = useState<NauticalLocationType[]>([])
    const [combinedLocations, setCombinedLocations] = useState<NauticalLocationType[]>(locations)

    const map = useMap()

        useEffect(() => {
            if(location === null) {
                map.invalidateSize()
            }
        }, [map, location])

    useEffect( () => {
        map.setView(mapCenter)
        onViewPortChange(map.getZoom(), map.getBounds())
        //     eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapCenter])

    useEffect(() => {
        setCombinedLocations(locations.concat(newLocations))
    }, [newLocations, locations])

    useEffect(() => {
        const filtered = newLocations.filter(l => l?.uuid !== createdNewLocation?.uuid)
        setNewLocations(filtered)
        //     eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdNewLocation])

    useEffect(() => {
        if (searchResult && searchResult.geo) {
            map.setView(firstPointOfGeometry(searchResult))
            setCombinedLocations([searchResult])
        }
        //     eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResult])

    const addCircularPolyToLocation = (location, center, radius) => {
        return {
            ...location,
            geo: circleToPolygon(center.coordinates, radius, 128), // todo: change number of edges based on radius?
            center: center,
            radius: radius
        }
    }

    const addGeoToLocation = (e) => {
        const shape = e;
        const shapeType = shape.layer.pm.getShape();

        const newLoc = createEmptyNauticalLocation('port');
        const geojson = e.layer.toGeoJSON();
        const radius = e.layer._mRadius;

        if(shapeType === 'Circle') {
            return addCircularPolyToLocation(newLoc, geojson.geometry, radius)
        }
        else {
            const polygonCenter = shape.layer.getCenter();
            const polygonCenterGeo = coordinatesToGeoJsonPoint(polygonCenter);

            newLoc['geo'] = geojson.geometry;
            newLoc['center'] = polygonCenterGeo;
            return newLoc;
        }
    }


    const leafletContainer = useMapEvents({
        moveend() {
            onViewPortChange(leafletContainer.getZoom(), leafletContainer.getBounds())
        },
        // @ts-ignore: Allow named editable events that are not defined in L.LeafletEventHandlerFnMap
        "pm:create": (e) => {
            if (e.layer && e.layer.pm) {
                const shape = e;
                shape.layer.pm.enable();
                setNewLocations(newLocations.concat(addGeoToLocation(shape)));

                // remove layer created by leaflet.PM => will be rendered by React through `allLocations`
                leafletContainer.removeLayer(shape.layer);
            }
        },
        "pm:remove": (e) => {
            if (e.layer && e.layer.pm) {
                const tempList = newLocations.filter(loc => loc.geo !== e.layer.feature.geometry)
                setNewLocations(tempList)
            }
        }
    });

    useEffect(() => {
        leafletContainer.pm.addControls({
            drawPolyline: false,
            drawCircleMarker: false,
            drawMarker: false,
            rotateMode: false
        });
        //     eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isSearchResult = (loc: NauticalLocationType) => {
        return searchResult ? loc.uuid === searchResult.uuid : false
    }

    if(!combinedLocations) return null

    return (
        <div className={"locations-geos"}>
            {
                combinedLocations.sort(nauticalLocationSortFunction)
                    .map(loc => <NauticalLocation key={loc.uuid} location={loc} isSearchResult={isSearchResult(loc)} onLocationClick={onForm}/>)
            }
        </div>
    )
}

export default NauticalLocationLayer
