import {EmptyNauticalLocation, NauticalLocationType} from "../../interfaces/INauticalLocation";
import React, {useEffect, useState} from "react";
import {addNewNauticalLocation, updateNauticalLocation} from "../../rest/updateNauticalLocation";
import PortFormInput from "./form-inputs/PortFormInput";
import BerthFormInput from "./form-inputs/BerthFormInput";
import AnchorAreaFormInput from "./form-inputs/AnchorAreaFormInput";
import NauticalLocationFormInput from "./form-inputs/NauticalLocationFormInput";
import TerminalFormInput from "./form-inputs/TerminalFormInput";
import {useForm} from "react-hook-form";
import PilotBoardingPlaceFormInput from "./form-inputs/PilotBoardingPlaceFormInput";
import QuayFormInput from "./form-inputs/QuayFormInput";
import {LocationTypes, nameOf, nauticalLocationTypes} from "../nautical-location/NauticalLocation.util";
import "./LocationEditor.css";
import {Button, Form, CloseButton} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {match} from "ts-pattern";
import AlertMessage, {AlertMessageProps} from "../alert/AlertMessage";
import {tokenStorage} from "../auth/TokenStorage";

type LocationEditorProps = {
    onClose: () => void
    location?: NauticalLocationType,
    onSubmit: (l: NauticalLocationType, n?: NauticalLocationType) => void
}

export const formatInputValue = (value) => {
    if(value) {
        return typeof value == 'object' ? JSON.stringify(value, null, ' ') : value
    } else {
        return ''
    }
}

export const formatInputArrayValue = (value) => {
    return Array.isArray(value) && value.length > 0 ? value.join(',') : ''
}

export const safeJsonParse = (maybeJsonStr) => {
    try {
        JSON.parse(maybeJsonStr)
        return true;
    } catch (err) {
        console.warn("Invalid JSON", err.message);
        return false;
    }
}

function isNew(l: NauticalLocationType): l is EmptyNauticalLocation {
    if(l) {
        return (l as EmptyNauticalLocation).isNewLocation
    } else {
        return false
    }
}

export const parseNumberFields = (numStr: string) => {
    const number = Number(numStr)
    return isNaN(number) ? numStr : Math.trunc(number)
}

export const LocationEditor: React.FC<LocationEditorProps> = ({location, onClose, onSubmit}) => {
    const [inputValues, setInputValues] = useState<NauticalLocationType>(location)
    const [locationType, setLocationType] = useState<string>(location?.type)
    const [isNewLocation, setIsNewLocation] = useState<boolean>(false)
    const { register, handleSubmit, setValue } = useForm()

    const [alerts, setAlerts] = useState<AlertMessageProps>(null)

    useEffect(() => {
        setLocationType(location?.type)
        setInputValues(location)
        setIsNewLocation(isNew(location))
        //setValue is required when user is drawing the polygon on the map because we have to refresh the formState
        setValue('geo', formatInputValue(location?.geo))
        //     eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const onInputChange = (key, value) => {
        const processedValue = match(key)
            .with('terminalTypes', () => {
                setValue(key, value)
                return value
            })
            .with('aliases', 'urns', 'ports', 'berthUuids', () => value.split(','))
            .with('mooringType', () => {
                if(value === 'NoMooringType') {
                    return null
                } else {
                    return value
                }
            })
            .with('bollards', () => {
                if(value) {
                    if (Array.isArray(value))  {
                        return value
                    } else {
                        return value.startsWith('{') || value.startsWith('[') ? JSON.parse(value) : inputStringToArrayOfString(value)
                    }
                } else {
                    return value
                }
            })
            .otherwise(() => {
                console.log('otherwise', key, value)
                return value
            })

        const updatedLocation: NauticalLocationType = {
            ...inputValues,
            [key]:  processedValue
        }

        setInputValues(updatedLocation)
    }


    const inputStringToArrayOfString = (str) => str ? str.split(',').map(elm => elm.trim()) : []
    const inputStringToArrayOfNumber = (str) => str ? str.split(',').map(elm => parseInt(elm.trim())) : []

    const parseInputAsJson = (key: string, str) => {
        return match(key)
                .with('aliases', 'urns', 'berthUuids', 'ports', () =>  inputStringToArrayOfString(str))
                .with('center', 'geo', () => JSON.parse(str))
                .with('bollards', () => {
                    return str.startsWith('{') || str.startsWith('[') ? JSON.parse(str) : inputStringToArrayOfNumber(str)
                })
                .with('bollardStart','bollardEnd', 'radius',  () => parseNumberFields(str))
                    .otherwise(() => str ? str : null)
    }

    const logAndAlertError = (err) => {
        let alertMsg = err.message ? err.message : err.error
        console.warn("Error encountered when updating location. ", alertMsg, err)
        setAlerts({alertType: 'danger', message: alertMsg})
    }

    const onSuccess = (msg) => {
        setAlerts({alertType: 'success', message: msg})
    }

    const handleFormSubmit = (data) => {
        const keyVals = Object.entries(data).map(([key, value]) => [key, parseInputAsJson(key, value)])
        let updatedNauticalLocation = Object.fromEntries(keyVals) as NauticalLocationType
        const token = tokenStorage.getAccessToken()

        const response = isNewLocation ? addNewNauticalLocation(token, updatedNauticalLocation) : updateNauticalLocation(token, updatedNauticalLocation)
        response
            .then(resp => {
                if(resp['error']) logAndAlertError(resp)
                else {
                    onSubmit(resp)
                    onSuccess("Updated location")
                }
            })
            .catch(logAndAlertError)
    }

    const getFormInputs = (nlType: string) => {
        switch (nlType) {
            case nameOf((LocationTypes.port)) : return <PortFormInput location={inputValues} register={register} onInputChange={onInputChange}/>
            case nameOf((LocationTypes.berth)): return <BerthFormInput location={inputValues} register={register} onInputChange={onInputChange}/>
            case nameOf((LocationTypes.terminal)): return <TerminalFormInput location={inputValues} register={register} onInputChange={onInputChange}/>
            case nameOf((LocationTypes.pilotBoardingPlace)): return <PilotBoardingPlaceFormInput location={inputValues} register={register} onInputChange={onInputChange}/>
            case nameOf((LocationTypes.quay)) : return <QuayFormInput location={inputValues} register={register} onInputChange={onInputChange}/>
            case nameOf((LocationTypes.anchorArea)): return <AnchorAreaFormInput location={inputValues} register={register} onInputChange={onInputChange}/>
            default: return <NauticalLocationFormInput location={inputValues} register={register} onInputChange={onInputChange}/>
        }
    }

    if(!inputValues) return null;
    else {
        return (
            <div className="col-lg-3">
                <AlertMessage alertType={alerts?.alertType} message={alerts?.message} />
                <div className="mb-1" style={{display: "flex", justifyContent: "flex-end", fontSize: "0.75rem"}}>
                    <CloseButton onClick={onClose}/>
                </div>
                <Form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Form.Select defaultValue={locationType} {...register("type", { required: true })} onChange={e => setLocationType(e.target.value)}>
                        {isNewLocation ?
                            nauticalLocationTypes.map(l =><option key={l.label}>{l.label}</option>)
                            :
                            <option key={location.type}>{location.type}</option>
                        }
                    </Form.Select>
                    {getFormInputs(locationType)}
                    <Button size="sm" variant="primary" type="submit">Submit</Button>
                </Form>
            </div>
            )
    }
};
