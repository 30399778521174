import NauticalLocationFormInput, {
    LocationFormInputProps
} from "./NauticalLocationFormInput";
import "../LocationEditor.css"
import {Form} from "react-bootstrap";
import {formatInputArrayValue, formatInputValue} from "../LocationEditor";

const QuayFormInput: React.FC<LocationFormInputProps> = ({location, register, onInputChange}) => {
    return (
        <Form.Group className="mb-3" controlId="QuayFormInput">
            <NauticalLocationFormInput location={location} register={register} onInputChange={onInputChange}/>
            <Form.Label>Berth UUIDs:</Form.Label>
            <Form.Control as="textarea" value={formatInputArrayValue(location['berthUuids'])} {...register("berthUuids")} onChange={ e => {
                onInputChange("berthUuids", e.target.value)
            }} />

            <Form.Label>Bollards:</Form.Label>
            <Form.Control as="textarea" value={location['bollards'] ? formatInputValue(location['bollards']): "[]" } {...register("bollards")} onChange={ e => {
                onInputChange("bollards", e.target.value)
            }}/>
        </Form.Group>

    );
}

export default QuayFormInput;
