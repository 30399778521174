import React from "react"
import {NauticalLocationType} from "../../../interfaces/INauticalLocation";
import "../LocationEditor.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Form} from "react-bootstrap";
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldValues} from "react-hook-form";
import {formatInputArrayValue, formatInputValue, safeJsonParse} from "../LocationEditor";


export type LocationFormInputProps = {
    location: NauticalLocationType,
    register: UseFormRegister<FieldValues>,
    onInputChange: (inputName, value) => void
}

const NauticalLocationFormInput: React.FC<LocationFormInputProps> = ({location, register, onInputChange}) => {
    return (
        <Form.Group className="mb-3" controlId="NauticalLocationFormInput">
            <Form.Label>UUID:</Form.Label>
            <Form.Control value={location.uuid} {...register("uuid", { required: true })} readOnly/>
            <Form.Label>URNS:</Form.Label>
            <Form.Control as="textarea" value={formatInputArrayValue(location.urns)} {...register("urns", { required: false })} onChange={ e => {
                onInputChange('urns', e.target.value)
            }} />
            <Form.Label>Name:</Form.Label>
            <Form.Control value={formatInputValue(location.name)} {...register("name")} onChange={ e => {
                onInputChange('name', e.target.value)
            }}/>
            <Form.Label>Unlocode / Port:</Form.Label>
            <Form.Control value={formatInputValue(location.port)} {...register("port", { required: true })} required onChange={ e => {
                onInputChange('port', e.target.value)
            }}/>

            <Form.Label>Aliases:</Form.Label>
            <Form.Control value={formatInputArrayValue(location.aliases)} {...register("aliases", { required: false })} onChange={ e => {
                onInputChange('aliases', e.target.value)
            }}/>
            <Form.Label>Geo:</Form.Label>
            <Form.Control as="textarea" rows={6} value={location.geo ? formatInputValue(location.geo) : "{}"}
                          {...register("geo", { required: true, validate: v => safeJsonParse(v) })} required
                          onChange={ e => {onInputChange('geo', e.target.value)}}
            />

        </Form.Group>
    );
}

export default NauticalLocationFormInput;
