import React, {useState} from 'react'
import {nauticalLocationTypes, NauticalLocationTypeSelect} from "./NauticalLocation.util";
import Select, {CSSObjectWithLabel} from "react-select";

type NauticalLocationTypeSelectorProps = {
    locationType: NauticalLocationTypeSelect[],
    callBack: (l: NauticalLocationTypeSelect[]) => void
}

const NauticalLocationTypeSelector: React.FC<NauticalLocationTypeSelectorProps> = ({locationType, callBack}) => {
    const [visibleLocationType, setVisibleLocationType] = useState(locationType)

    const onChange = (e) => {
        setVisibleLocationType(e)
        callBack(e)
    }



    return (
        <Select
            defaultValue={visibleLocationType}
            isMulti
            name="location-type"
            // @ts-ignore
            options={nauticalLocationTypes}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{ menu: base => ({ ...base, zIndex: 99999 }) as CSSObjectWithLabel }}
            onChange={onChange}
        />
    )
}

export default NauticalLocationTypeSelector;
