import NauticalLocationFormInput, {LocationFormInputProps} from "./NauticalLocationFormInput";
import "../LocationEditor.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Form} from "react-bootstrap";
import {formatInputArrayValue} from "../LocationEditor";

const AnchorAreaFormInput: React.FC<LocationFormInputProps> = ({location, register, onInputChange}) => {
    return (
        <Form.Group className="mb-3" controlId="AnchorAreaFormInput">
            <NauticalLocationFormInput location={location} register={register} onInputChange={onInputChange}/>
            <Form.Label>Ports:</Form.Label>
            <Form.Control value={formatInputArrayValue(location['ports'])} {...register("ports", { required: false })} onChange={ e => {
                onInputChange('ports', e.target.value)
            }}/>

        </Form.Group>
    );
}

export default AnchorAreaFormInput;
