import NauticalLocationFormInput, {LocationFormInputProps} from "./NauticalLocationFormInput";
import "../LocationEditor.css";
import {Form} from "react-bootstrap";
import {formatInputArrayValue} from "../LocationEditor";
import {terminalTypesSelector} from "../../nautical-location/NauticalLocation.util";
import Select, {CSSObjectWithLabel} from "react-select";
import React, {useMemo} from "react";



const TerminalFormInput: React.FC<LocationFormInputProps> = ({ location, register, onInputChange }) => {
    const terminalTypes = useMemo(() => 'terminalTypes' in location ? location.terminalTypes : [], [location])

    const selectedTerminalTypes = useMemo(() => {
        const terminalStringTypes = terminalTypes.map((t) => t.toString())

        return terminalTypesSelector.filter(({value}) =>  typeof value === "string" && terminalStringTypes.includes(value))
    }, [terminalTypes] )


    register("terminalTypes", { value: terminalTypes  });

    return (
        <Form.Group className="mb-3" controlId="TerminalFormInput">
            <NauticalLocationFormInput location={location} register={register} onInputChange={onInputChange} />
            <Form.Label>Berth UUIDs:</Form.Label>
            <Form.Control
                value={formatInputArrayValue(location["berthUuids"])}
                {...register("berthUuids", { required: false })}
                onChange={(e) => {
                    onInputChange("berthUuids", e.target.value);
                }}
            />
            <Form.Label>Terminal Types:</Form.Label>
            <Select
                value={selectedTerminalTypes}
                isMulti
                name="terminalTypes"
                options={terminalTypesSelector}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={{ menu: base => ({ ...base, zIndex: 99998 }) as CSSObjectWithLabel }}
                onChange={selectedOptions => onInputChange("terminalTypes", selectedOptions.map(({value}) => value ))}
            />
        </Form.Group>
    );
};

export default TerminalFormInput;
