import React from "react";
import LoginButton from "../auth/LoginButton";
import { ProntoLogo } from '../pronto-logo/ProntoLogo'
import './Header.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from "./menu";

const Header: React.FC<{ isAuthenticated: boolean, setIsAuthenticated: (auth: boolean) => void, email: string, errorMessage: string }> = ({ isAuthenticated, setIsAuthenticated, email, errorMessage }) => {
    const content = isAuthenticated ? <Menu email={email} setIsAuthenticated={setIsAuthenticated} /> : <LoginButton setIsAuthenticated={setIsAuthenticated} />;

    return (
        <header className="p-2 mb-1">
            <div className="container-fluid">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <ProntoLogo className="logo" />
                        <div className="logoText ms-2">Nautical Location Master Database</div>
                    </div>
                    <div className="flex-grow-1 d-flex justify-content-end" style={{ width: "10%" }}>
                        {content}
                    </div>
                </div>
                {errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>}
            </div>
        </header>
    );
};

export default Header;
