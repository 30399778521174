import {NauticalLocationType} from "../interfaces/INauticalLocation";
import {handleResponse} from "./handleResponse";
import {createHttpPostOrPutOptions} from "./request.util";

export const updateNauticalLocation = (authToken: string, updatedLocation: NauticalLocationType): Promise<NauticalLocationType> => {
    const url = `/locations/${updatedLocation.uuid}`
    const options = createHttpPostOrPutOptions(authToken, 'PUT', updatedLocation)
    // It is not fetch, it is update the nautical location
    return fetch(url, options).then(handleResponse)
}

export const addNewNauticalLocation = (authToken: string, newLocation: NauticalLocationType): Promise<NauticalLocationType> => {
    const url = `/locations`
    const options = createHttpPostOrPutOptions(authToken, 'POST', newLocation)
    return fetch(url, options)
        .then(handleResponse)
}
