import React from "react";
import { tokenStorage } from './TokenStorage';
import {Redirect} from "react-router-dom";

const LogoutButton: React.FC<{ setIsAuthenticated: (auth: boolean) => void }> = ({ setIsAuthenticated }) => {
    tokenStorage.deleteTokens();
    setIsAuthenticated(false);
    return <Redirect to="/" push={true} />;
};

export default LogoutButton;
