import React from "react";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, isAuthenticated, setErrorMessage, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            try{
                if (isAuthenticated) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/" />;
                }
            }
            catch(e) {
                setErrorMessage('error ', e);
            }
        }}
    />
);

export default ProtectedRoute;
