import React from "react";
import Select, {CSSObjectWithLabel} from "react-select";
import {berthMooringTypesSelector, NauticalLocationTypeSelect} from "./NauticalLocation.util";

type BerthTypeSelectProps = {
    callback: (types: NauticalLocationTypeSelect[]) => void
}

const BerthTypeSelector: React.FC<BerthTypeSelectProps> = ({callback}) => {

    const onChange = (e: NauticalLocationTypeSelect[]) => {
        callback(e)
    }

    return(
        <Select
            defaultValue={berthMooringTypesSelector}
            isMulti
            name="berth-mooring-type"
            // @ts-ignore
            options={berthMooringTypesSelector}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{ menu: base => ({ ...base, zIndex: 99998 }) as CSSObjectWithLabel }}
            onChange={onChange}
        />
    )

}

export default BerthTypeSelector;
