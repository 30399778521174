import {NauticalLocationType} from "../interfaces/INauticalLocation";

export const createHeaders = (authToken: string) => ({
    'Authorization': 'Bearer ' + authToken
})

export const createHttpPostOrPutOptions = (authToken: string, method: string, location: NauticalLocationType) => ({
    method: method,
    headers: {
        ...(createHeaders(authToken)),
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(location)
})

export const createHttpGetOptions = (authToken: string) => ({
    method: 'GET',
    headers: {'Authorization': `Bearer ${authToken}`, 'Accept': 'application/json'}
})

export const createHttpPostOrPutOptionsWithAny = (authToken: string, method: string) => ({
    method: method,
    headers: {
        ...(createHeaders(authToken)),
        'Content-Type': 'application/json'
    }
})
