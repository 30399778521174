import React, {useState} from "react";
import Select, {CSSObjectWithLabel} from "react-select";
import {ESALG, NLRTM, USHOU, GBMSC, USMIA, USNYC} from "../../constants/NauticalLocationConstants";
import { StylesConfig } from 'react-select';

const portOptions = [
    {value: NLRTM, label: 'NLRTM'},
    {value: USHOU, label: 'USHOU'},
    {value: ESALG, label: 'ESALG'},
    {value: GBMSC, label: 'GBMSC'},
    {value: USMIA, label: 'USMIA'},
    {value: USNYC, label: 'USNYC'},
]

type PortSelectorProps = {
    callback: (selectedPort: string) => void
}

const customStyles: StylesConfig<any, false> = {
    menu: (base) => ({
        ...base,
        zIndex: 99999,
    } as CSSObjectWithLabel),
    // Add other style overrides here if needed
};

export const PortSelector: React.FC<PortSelectorProps> = ({callback}) => {
    const [port, setPort] = useState({value: NLRTM, label: 'NLRTM'})
    const onPortChange = (e: {value: string, label:string}) => {
        setPort(e)
        callback(e.value)
    }

    return (
        <div>
            <Select
                options={portOptions}
                onChange={onPortChange}
                value={port}
                styles={customStyles}
            />
        </div>
    )
}
