import {AuthenticationResultType} from "@aws-sdk/client-cognito-identity-provider";
import jwtDecode from 'jwt-decode';

const ACCESS_TOKEN_NAME = "access_token";
const ACCESS_TOKEN_EXPIRES_AT = "access_token_expires_at";
const ID_TOKEN_NAME = "id_token";
const ID_TOKEN_EXPIRES_AT = "id_token_expires_at";

interface DecodedToken {
  email: string;
}

export class TokenStorage {
  private saveAccessToken = (session: AuthenticationResultType) => {
    const token = session.AccessToken
    const expiresIn = session.ExpiresIn
    const expiresAt = expiresIn * 1000 + this.getEpochMilliseconds();
    localStorage.setItem(ACCESS_TOKEN_NAME, token);
    localStorage.setItem(ACCESS_TOKEN_EXPIRES_AT, `${expiresAt}`);
  }

  private saveIdToken = (session: AuthenticationResultType) => {
    const token = session.IdToken
    const expiresIn = session.ExpiresIn
    const expiresAt = expiresIn * 1000 + this.getEpochMilliseconds();
    localStorage.setItem(ID_TOKEN_NAME, token);
    localStorage.setItem(ID_TOKEN_EXPIRES_AT, `${expiresAt}`);
  }

  saveTokensIfExists = (session: AuthenticationResultType) => {
    if (session && typeof session.AccessToken !== 'undefined' && typeof session.IdToken !== 'undefined') {
      this.saveAccessToken(session);
      this.saveIdToken(session);
    }
  }

  deleteTokens() {
    localStorage.removeItem(ID_TOKEN_NAME);
    localStorage.removeItem(ID_TOKEN_EXPIRES_AT);
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(ACCESS_TOKEN_EXPIRES_AT);
  }

  isAuthenticated() {
    if (this.getAccessToken() !== undefined) {
      return true;
    } else {
      this.deleteTokens();
      return false;
    }
  }

  getEmail = () => {
    try {
      const idToken = this.getIdToken();
      const decodedToken = jwtDecode<DecodedToken>(idToken);
      return decodedToken.email;
    } catch (e) {
      return "";
    }
  }

  getAccessToken() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    if (token && !this.isAccessTokenExpired()) {
      return token;
    } else {
      return undefined;
    }
  }

  getIdToken = () =>  {
    const token = localStorage.getItem(ID_TOKEN_NAME);
    if (token && !this.isIdTokenExpired()) {
      return token;
    } else {
      return undefined;
    }
  }

  private getEpochMilliseconds = () => { return new Date().getTime() };
  private isIdTokenExpired = () => this.isExpired(localStorage.getItem(ID_TOKEN_EXPIRES_AT));
  private isAccessTokenExpired = () => this.isExpired(localStorage.getItem(ACCESS_TOKEN_EXPIRES_AT));

  private isExpired(expiresAt: string) {
    if (expiresAt) {
      return this.getEpochMilliseconds() >= parseInt(expiresAt);
    } else {
      return true;
    }
  }
}

export const tokenStorage = new TokenStorage();
