import React, {useEffect, useRef, useState} from "react";
import {GeoJSON, Tooltip} from "react-leaflet";
import {
    coordinatesToGeoJsonPoint,
    EmptyNauticalLocation,
    NauticalLocationType
} from "../../interfaces/INauticalLocation";
import {featureColour} from "../../constants/NauticalLocationConstants";
import "../location-editor/LocationEditor.css";
type NauticalLocationProps = {
    location?: NauticalLocationType,
    isSearchResult: boolean,
    onLocationClick: (l: NauticalLocationType) => void,
}

const NauticalLocation: React.FC<NauticalLocationProps> = ({location, isSearchResult, onLocationClick}) => {
    const [locationData, setLocationData] = useState<NauticalLocationType>(location)

    const noInitialRender = useRef(false)


    const onEachFeature = (feature, layer) => {
        layer.on("pm:edit", (e) => {
            const updatedGeoJson = e.layer.toGeoJSON().geometry
            const center = e.layer.getCenter()
            let updatedLocationData: EmptyNauticalLocation = {
                ...locationData,
                geo: updatedGeoJson,
                center: coordinatesToGeoJsonPoint(center)
            }
            setLocationData(updatedLocationData)
        })

        layer.on('click', (_) =>{
            onLocationClick(locationData)
        })
    }

    useEffect(() => {
        if (noInitialRender.current) {
            onLocationClick(locationData)
        } else {
            noInitialRender.current = true
        }
        //     eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationData])


    return (
        <div className={'geo-feature'}>
            <GeoJSON key={locationData.uuid} data={locationData.geo} onEachFeature={onEachFeature} style={featureColour(locationData.type)}>
                <Tooltip sticky permanent={isSearchResult}>
                    <div><b>{locationData.type}</b></div>
                    <ul>
                        <li>{
                            // name is not available for berth, so retrieving like this
                            locationData.name ? locationData.name : locationData['nameShort']
                        }</li>
                        <li>{locationData.port}</li>
                    </ul>
                </Tooltip>
            </GeoJSON>
        </div>
    )
}

export default NauticalLocation;
